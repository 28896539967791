/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import HeaderNoLinks from "./headerNoLinks"
import "./layout.scss"

const Layout3 = ({ children }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery3 {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <HeaderNoLinks siteTitle={data.site.siteMetadata.title} />
            <div
                style={{
                    margin: `0 auto`,
                    // maxWidth: 960,
                    // padding: `0 1.0875rem 1.45rem`,


                }}
            >
                <main>{children}</main>
                {/* <footer>
          © {new Date().getFullYear()}, Mail Sculpt
          {` `}
        </footer> */}
            </div>
        </>
    )
}

Layout3.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout3
